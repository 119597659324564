<script>
  let audio;

  const bkbHeaa = () => audio.play();
</script>

<button on:click={bkbHeaa}>⛪</button>
<h1>bkb.church</h1>

<audio bind:this={audio} src="assets/BKBHEAA.mp3" />

<!-- <iframe
  class="pong-desktop"
  src="https://scratch.mit.edu/projects/1113770053/embed"
  allowtransparency="true"
  width="485"
  height="402"
  frameborder="0"
  scrolling="no"
  allowfullscreen
></iframe> -->

<iframe
  class="pong-mobile"
  src="https://scratch.mit.edu/projects/1113781517/embed"
  allowtransparency="true"
  width="485"
  height="402"
  frameborder="0"
  scrolling="no"
  allowfullscreen
></iframe>

<style>
  button {
    font-size: 4em;
    margin: 0 auto;
  }
</style>
